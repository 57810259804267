@import '../../scss/mixin';
@import '../../scss/variables';

.movies {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  text-align: center;

  h1 {
    margin-bottom: 1vh;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -1.5px;

    @include mq('md') {
      font-size: 2.5rem;
      line-height: 1.3;
      letter-spacing: -2px;
    }
    @include mq('ll') {
      font-size: 3rem;
      line-height: 1.4;
      letter-spacing: -3px;
    }
  }

  p {
    margin-bottom: 5vh;
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: -.5px;

    span {
      font-weight: 700;
    }

    br {
      display: none;
      @include mq('md') {
        display: block;
      }
    }

    @include mq('md') {
      font-size: 1.3rem;
      line-height: 1.3;
      letter-spacing: -1px;
    }
    @include mq('ll') {
      font-size: 1.6rem;
      line-height: 1.2;
    }

    &.error {
      margin-bottom: .5vh;
    }
  }

  &__wrp {
    margin-top: 3vh;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 3vw 1rem;

    @include mq(400px) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    @include mq(670px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  &__container {
    width: 100%;
  }

  .button {
    margin: 2rem 1.5rem 5vh;
    padding: 1em 1.2em;
    border-radius: 5px;
    background-color: $light-black;
    outline: none;
    box-shadow: none;
    border: 0;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease-out;

    &:disabled {
      background-color: #1f1f1f;
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:hover {
      cursor: pointer;
      transform: scale(0.96);
    }

    a {
      color: $white;
      font-family: $main-font;
      font-size: 15px;
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__memoji {
    transform-origin: bottom;
    &:hover {
      cursor: pointer;
      animation: tilt .4s ease-out;
    }
  }
}

@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}