@import '../../scss/mixin';
@import '../../scss/variables';

.navbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 70px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4vw;
  background-color: transparent;
  transition: all 0.2s ease-out;

  &__scrolled {
    background-color: $light-black;
  }

  &__logo {
    max-width: 130px;
    width: 100%;

    @include mq(600px) {
      max-width: 150px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__navlinks {
    margin-left: 2rem;

    &--link {
      display: inline-block;
      margin: 0 12px;
      font-size: 14px;
      pointer-events: all;
      cursor: pointer;

      @include mq('sm'){
        font-size: 16px;
      }
      @include mq('md'){
        font-size: 1.8vw;
      }
      @include mq('lg'){
        font-size: 1.2vw;
      }
      @include mq(1330px){
        font-size: 18px;
      }

      a {
        color: $light-black;
        text-decoration: none;
        cursor: pointer;
        font-weight: 600;
        transition: opacity .2s ease-in-out;

        &:hover {
          opacity: .5;
        }

        &.scrolled {
          color: $white;
        }
      }

      .activeNavLink {
        color: $white;
        font-weight: 700;
        cursor: default;

        &:hover {
          color: $white;
        }
      }
    }
  }
}