@import '../../scss/mixin';
@import "../../scss/variables";

.movie {
  position: relative;
  overflow: hidden;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  width: 100%;
  transform: scale(1);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  cursor: pointer;

  img {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: $default-radius;
  }

  &__fallback {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    min-width: 100%;
    min-height: 110px;
    border-radius: $default-radius;
    text-align: center;
    font-weight: 500;
    z-index: -1;
    padding: 0 1em;
  }

  &__info {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(15%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 1rem;
    opacity: 0;
    z-index: 2;
    border-radius: $default-radius;
    pointer-events: none;
    transition: all .4s ease-out;

    @include mq('md') {
      pointer-events: all;
    }

    &--title {
      font-size: 1rem;
      font-weight: 600;
      text-transform: none;
      color: $white;

      @include mq('lg') {
        font-size: 1.2rem;
      }

      @include mq(1680px) {
        font-size: 1.3rem;
      }
    }

    &--vote {
      margin-top: 0.25rem;
      font-size: .7rem;
      font-weight: 600;
      text-transform: none;
      color: $white;
      opacity: .7;

      @include mq('lg') {
        font-size: .8rem;
      }

      @include mq(1680px) {
        font-size: 1rem;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $default-radius;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, transparent 65%);
    z-index: 0;
    opacity: 0;
    box-sizing: border-box;
    transition: opacity 0.2s ease-out;
  }

  @include mq('md') {
    &:hover {
      transform: translateY(-10px) !important;
      z-index: 1;
      opacity: 1;

      &::after {
        opacity: 1;
      }

      .movie__info {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}