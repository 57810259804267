$white: #f2f2f2;
$pure-black: #030303;
$light-black: #181818;
$black: #000000;
$red: #e50914;
$grey: #101011;
$blue-google: #1356c7;
$sand: #c5c0b2;

$main-font: 'Inter', sans-serif;
$moodflix-font: 'Bebas Neue', cursive;

$default-radius: 10px;

$skeleton-shine-color: rgba(255,255,255,0.2);
$skeleton-animation-duration: 5s;
$skeleton-easing-function: ease-in-out;
$skeleton-iteration-count: infinite;