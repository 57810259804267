@import '../../scss/mixin';
@import "../../scss/variables";

.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($light-black, 0.7);
    opacity: 1;
    pointer-events: all;
    z-index: 98;

    &.modal__invisible {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
  }

  &__wrp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 90vw;
    height: calc(100vh - 100px);
    overflow-y: auto;
    max-width: 100%;
    max-height: 100%;
    z-index: 99;
    pointer-events: all;
    background: $sand;
    border-radius: 5px;

    @include mq('sm') {
      width: 80vw;
    }

    @include mq('md') {
      width: 65vw;
    }

    @include mq('lg') {
      width: 55vw;
    }

    @include mq('ll') {
      max-width: 750px;
      width: 100%;
    }

    &.modal__invisible {
      z-index: -1;
      pointer-events: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__closebtn {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 1;
    display: inline-flex;
    padding: 8px;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    margin-bottom: 0.8em;
    background: $light-black;
    color: $white;
    border: 2px solid $white;
    transition: all 0.3s ease-out;
    outline: none;

    &:hover {
      background: $white;
      color: rgba($light-black, 0.5);
    }

    svg {
      stroke-width: 1px;
    }
  }

  &__image {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__info {
    &--wrp {
      padding: 1em 1.5em;

      @include mq('sm') {
        padding: 1.5em 2em;
      }
    }

    &--title {
      font-size: 22px;
      line-height: 1.4;
      font-weight: 600;

      @include mq('sm') {
        font-size: 26px;
      }

      span {
        font-size: 20px;
        font-weight: 700;
        opacity: .8;
      }
    }

    &--description {
      margin-top: .5rem;
      font-size: 14px;
      line-height: 1.5;

      @include mq('sm') {
        font-size: 16px;
      }
    }
  }

  &__section {
    &--wrp {
      margin-top: 0.5rem;
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      gap: 0.4rem;
    }

    &--title {
      margin-top: 1.5rem;
      font-size: 1.2rem;
      line-height: 1.4;
      font-weight: 600;

      @include mq('sm') {
        font-size: 1.4rem;
      }
    }
  }

  &__keyword {
    padding: 0.7rem 0.8rem;
    border-radius: 18px;
    display: inline-block;
    background-color: rgba(129, 129, 129, 0.3);
    color: $light-black;
    font-size: 0.8rem;
    font-weight: 500;
  }

  &__review {
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mq('md') {
      flex-direction: row;
    }

    &--img {
      width: 25%;

      @include mq('md') {
        width: 100%;
      }

      &-wrp {
        width: 100%;
        max-width: 75%;
        display: flex;
        align-items: center;

        @include mq('md') {
          margin-right: 3%;
          max-width: 17%;
          flex-direction: column;
          text-align: center;
        }

        p {
          width: 100%;
          display: block;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    &--content {
      font-size: 14px;
      line-height: 1.5;
      word-break: break-word;

      @include mq('sm') {
        font-size: .9rem;
      }

      a {
        color: $black;
        font-weight: 500;
      }
    }
  }

  &__skeleton {
    height: 100%;

    &--contentWrp {
      padding: 1em 1.5em;

      @include mq('md') {
        padding: 1.5em 2em;
      }
    }
  }
}