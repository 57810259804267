@import '../../scss/mixin';
@import '../../scss/variables';

.selfieCamera {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cam {
    border-radius: 50%;
    object-fit: cover;

    &__wrp {
      width: 100%;
      height: 100%;
      max-width: 400px;
      max-height: 400px;
      border-radius: 100%;
      transition: all .7s cubic-bezier(0.6, -0.05, 0.01, 0.99);

      @include mq('md') {
        max-width: 500px;
        max-height: 500px;
      }

      &.recorded {
        animation: .7s forwards shrinkCircle;
      }

      video {
        border-radius: 100%;
      }
    }
  }

  .button {
    margin: 0 1.5rem;
    margin-top: 2rem;
    padding: 1em 1.2em;
    border-radius: 5px;
    background-color: $light-black;
    font-family: $main-font;
    font-size: 15px;
    font-weight: 600;
    color: $white;
    outline: none;
    box-shadow: none;
    border: 0;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease-out;

    &:disabled {
      background-color: #1f1f1f;
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:hover {
      cursor: pointer;
      transform: scale(0.96);
    }
  }
}

@keyframes expandCircle {
  0% {
    clip-path: circle(0% at 50% 50%);
  }
  100% {
    clip-path: circle(50% at 50% 50%);
  }
}

@keyframes shrinkCircle {
  0% {
    clip-path: circle(50% at 50% 50%);
  }
  100% {
    clip-path: circle(0% at 50% 50%);
  }
}